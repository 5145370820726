<!--
 * @Author: zhulin
 * @Date: 2020-07-14 16:55:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-22 16:23:12
 * @Description: 查看流程块(查看流程和日志使用到)
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\seeFlow\components\cardList.vue
-->
<template>
  <div class="seeFlowList" v-en-loading="loadingFlow">
    <div class="list" v-if="flowData.length > 0">
      <dl v-for="(item, index) in flowData" :key="index">
        <p class="time">{{ approvalShowTime(item) }}</p>
        <en-icon :name="'iconchakanliucheng' + item.imgFlag" size="16" :style="{ color: statusIconColor(item.imgFlag) }" class="status-icon"></en-icon>
        <dd class="main-cent">
          <div class="head">
            <en-user-logo :user-name="item.pattern === '006' ? item.nodeName : item.name" :image-url="item.userLogo" size="34"></en-user-logo>
          </div>
          <div class="cent">
            <div class="name">
              <span class="main-name">{{ item.pattern === "006" ? item.nodeName : item.name }}</span>
              <div class="grant-name" v-if="agentPerson(item)">
                <en-icon name="iconshoufuduixiang-kongjian" size="16"></en-icon>
                <span class="txt">{{ item.ownerName }}</span
                >代理执行
              </div>
            </div>
            <p class="opinion">{{ item.suggest | filtersSuggest }}</p>
            <!-- <p class="opinion">{{ item.suggest === "[img]" ? "" : item.suggest }}</p> -->
            <p class="flowname">{{ item.pattern === "006" ? "分支开始到分支结束" : item.nodeName }}</p>
          </div>
          <div class="info">
            <div class="btn-line">
              <div class="btn-item" v-if="approvalBtn(item)" @click="changeTab(item, '1')">
                <en-icon name="iconshouqian" size="14"></en-icon>
                <span class="txt">补充意见</span>
              </div>
              <div class="btn-item" v-if="notifierBtn(item)" @click="changeTab(item, '2')">
                <en-icon name="icontongzhiren" size="14"></en-icon>
                <span class="txt">通知人</span>
              </div>
              <div class="btn-item" v-if="signBtn(item)" @click="changeTab(item, '3')">
                <en-icon name="iconshouqian" size="14"></en-icon>
                <span class="txt">手签</span>
              </div>
              <en-icon name="iconzhankai" size="12" class="show-process" v-if="item.pattern === '006'" @click.native.stop="changeTab(item, '4', index)"></en-icon>
            </div>
          </div>
        </dd>
        <!--'补充意见内容'-->
        <dd class="approval-cent" v-if="item.showApproval && tabCur === item.id && flag === '1'">
          <el-input type="textarea" v-model="approvalForm.desc" class="desc" placeholder="请输入补充意见"></el-input>
          <en-button type="primary" class="btns" @click="addSuppleApproval(item, index)">确定</en-button>
        </dd>
        <!--'通知人内容'-->
        <dd class="notifier-cent" v-if="item.showNotifier && tabCur === item.id && flag === '2'">
          <div class="person-item" v-for="(p, pindex) in item.notifyRange || ''" :key="pindex">
            <en-user-logo :user-name="p.name" :image-url="p.userLogo" size="34"></en-user-logo>
            <span class="name">{{ p.name }}</span>
          </div>
        </dd>
        <!--'手签内容'-->
        <dd class="sign-cent" v-if="item.showSign && tabCur === item.id && flag === '3'">
          <img :src="getApprovalComment(item, 3)" alt="" />
        </dd>
        <!--'并签内容'-->
        <div class="process-cent" v-if="item.showProcess && tabCur === item.id && flag === '4'">
          <div class="branch-list">
            <div v-for="(bpitem, bpindex) in brancData" :key="bpindex" class="list branch-mod">
              <dl v-for="(bitem, bindex) in bpitem" :key="bindex">
                <en-icon :name="'iconchakanliucheng' + bitem.imgFlag" size="16" :style="{ color: statusIconColor(bitem.imgFlag) }" class="status-icon"></en-icon>
                <dd class="main-cent">
                  <div class="head">
                    <en-user-logo :user-name="bitem.name" :image-url="bitem.userLogo" size="34"></en-user-logo>
                  </div>
                  <div class="cent">
                    <div class="name">
                      <span class="main-name">{{ bitem.name }}</span>
                      <div class="grant-name" v-if="agentPerson(bitem)">
                        <en-icon name="iconshoufuduixiang-kongjian" size="16"></en-icon>
                        <span class="txt">{{ bitem.ownerName }}</span
                        >代理执行
                      </div>
                    </div>
                    <p class="opinion">{{ bitem.suggest | filtersSuggest }}</p>
                    <p class="flowname">{{ bitem.nodeName }}</p>
                  </div>
                  <div class="info">
                    <p class="time">{{ bitem.modifyTime }}</p>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </dl>
    </div>
    <en-result type="NoData" v-else></en-result>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { tripService } from "@/api";
import { flowAndLog } from "@/api/businessModel";

export default {
  name: "seeFlowList",
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    itemInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    showList: {
      type: Boolean,
      default: false
    },
    isLogIn: {
      type: Boolean,
      default: false
    },
    // 来自我的行程id
    tripDataId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loadingFlow: false,
      flowData: [],
      mdInfo: {},
      approvalForm: {},
      tabCur: "",
      flag: "",
      // 流程状态
      statusData: [
        {
          id: "000",
          name: "提交",
          color: "#26c393"
        },
        {
          id: "001",
          name: "待处理",
          color: "#3e90fe"
        },
        {
          id: "002",
          name: "审批中",
          color: "#ffad2c"
        },
        {
          id: "003",
          name: "已审批",
          color: "#26c393"
        },
        {
          id: "004",
          name: "退回",
          color: "#f76b6b"
        },
        {
          id: "005",
          name: "收回",
          color: "#ffad2c"
        },
        {
          id: "006",
          name: "终止",
          color: "#f76b6b"
        },
        {
          id: "007",
          name: "补充意见",
          color: "#26c393"
        },
        {
          id: "008",
          name: "弃权",
          color: "#f76b6b"
        },
        {
          id: "009",
          name: "终止",
          color: "#f76b6b"
        }
      ],
      showApproval: false,
      showNotifier: false,
      showSign: false,
      showProcess: false,
      branchFlag: false,
      brancData: [] // 并签数据,
    };
  },
  mounted() {
    this.mdInfo = this.data.mdInfo;
    this.queryFlowDetail();
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  filters: {
    filtersSuggest(data) {
      const imgs = data?.includes("[img]");
      const aa = imgs ? "" : data;
      return aa;
    }
  },
  methods: {
    /**
     * @description: 查询流程数据
     * @param {businessKey}
     */
    async queryFlowDetail() {
      try {
        this.loadingFlow = true;
        const pa = { businessKey: this.data.id };
        if (this.isLogIn) {
          pa.businessKey = this.itemInfo.dataId;
          pa.processInstanceId = this.itemInfo.processInstanceId;
        }
        let rsp = [];
        if (!this.tripDataId) {
          rsp = await flowAndLog.queryFlowDetail(pa);
        } else {
          rsp = await tripService.queryWfQueryFlowDetail({ businessKey: this.tripDataId });
        }
        this.flowData = rsp;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingFlow = false;
      }
    },
    statusIconColor(d) {
      const target = this.statusData.find((item) => item.id === d);
      return target.color || "";
    },
    changeTab(item, flag, index) {
      if (this.tabCur === item.id && this.flag === flag) {
        this.tabCur = "";
      } else {
        this.tabCur = item.id;
        this.flag = flag;
      }
      if (flag === "4") {
        if (this.branchFlag) {
          item.showProcess = !item.showProcess;
        } else {
          item.showProcess = true;
          this.queryFlowBranchDetail(item, index);
        }
      }
      this.$set(this.flowData, index, item);
    },
    /**
     * @description: 代理执行人是否展示
     */
    agentPerson(item) {
      return !!(item.isSupplyApproval !== "0" && item.owner && item.emId !== item.owner && item.imgFlag !== "002");
    },
    /**
     * @description: 补充意见按钮是否展示
     */
    approvalBtn(item) {
      let showbtn = false;
      if (item.owner === this.userInfo.userId && item.status === "001" && this.mdInfo.billStatus !== "003" && this.mdInfo.billStatus !== "004" && this.mdInfo.billStatus !== "008" && !this.isLogIn) {
        item.showApproval = true;
        showbtn = true;
      } else {
        item.showApproval = false;
        showbtn = false;
      }
      return showbtn;
    },
    // 显示的审批时间 addby:lpx 2021-03-19
    approvalShowTime(item) {
      // isSupplyApproval = "0" 是补充审批, 其他的是不能显示审批时间的状态
      if (!(!item.status || item.status === "000" || item.status === "002" || item.status === "003" || item.status === "004") || item.isSupplyApproval === "0") {
        return item.modifyTime;
      }
      return "";
    },
    /**
     * @description: 审批人按钮是否展示
     */
    notifierBtn(item) {
      let showbtn = false;
      if (item.notifyRange && item.notifyRange.length > 0) {
        item.showNotifier = true;
        showbtn = true;
      } else {
        item.showNotifier = false;
        showbtn = false;
      }
      return showbtn;
    },
    /**
     * @description: 手签按钮是否展示
     */
    signBtn(item) {
      let showbtn = false;
      if (item.suggest && item.suggest.indexOf("[img]") > -1) {
        item.showSign = true;
        showbtn = true;
      } else {
        item.showSign = false;
        showbtn = false;
      }
      return showbtn;
    },
    /**
     * @description: 处理手签图片
     */
    getApprovalComment(item, flg) {
      // flg 1 返回意见()，2返回手签图标 3 返回手签图片
      // 所有的补充意见都放到<p class="approval>
      // 返回的手签图标放到原有位置
      let str = "";
      if (flg === 2) {
        if (item.suggest && item.suggest.indexOf("[img]") > -1) {
          str = "<div class='handsign'></div>";
        } else {
          str = "<p class='opinion'></p>";
        }
      } else if (flg === 3) {
        if (item.approvalComment && item.approvalComment.indexOf("[img]") > -1) {
          // 需要截取掉图片
          const sImg = item.suggest.substring(5);
          console.log("sImg", sImg, item.suggest);
          // console.log("sImg")
          str = sImg;
        } else {
          str = "";
        }
      }
      return str;
    },
    /**
     * @description: 提交补充意见 /flAddSuppleApproval
     * @return:
     */
    async addSuppleApproval(item, index) {
      if (!this.approvalForm.desc) {
        this.$message({
          message: "请输入补充意见"
        });
      } else {
        const pa = {
          pId: item.id,
          owner: item.owner,
          nodeId: item.nodeId,
          businessKey: this.data.id,
          processInstanceId: item.processInstanceId,
          approvalComment: this.approvalForm.desc
        };
        if (this.tripDataId) {
          pa.businessKey = this.tripDataId;
        }
        await flowAndLog.addSuppleApproval(pa);
        this.$message({
          message: "提交成功"
        });
        this.queryFlowDetail();
        item.showApproval = false;
        this.approvalForm.desc = "";
        this.$set(this.flowData, index, item);
        this.changeTab(item, "0");
      }
    },
    /**
     * @description: 请求并签数据 /wfQueryFlowBranchDetail 暂用模拟数据代替
     * @return:
     */
    async queryFlowBranchDetail(item) {
      const pa = {
        businessKey: this.data.id,
        pId: item.id,
        nodeId: item.nodeId,
        processInstanceId: item.processInstanceId
      };
      if (this.tripDataId) {
        pa.businessKey = this.tripDataId;
      }
      try {
        this.loadingFlow = true;
        const rsp = await flowAndLog.queryFlowBranchDetail(pa);
        this.brancData = rsp;
        this.branchFlag = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingFlow = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.seeFlowList {
  border-radius: 4px;
  .list {
    border-top: 1px solid #e8ecf2;
    padding-right: 20px;
    position: relative;
    overflow: hidden;
    text-align: left;
    padding-bottom: 20px;
    p {
      margin: 0;
    }
    .main-cent {
      position: relative;
      padding: 15px;
      zoom: 1;
      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }
    dd {
      margin: 0;
    }
    > dl {
      background: #fff;

      border-radius: 5px;
      width: calc(100% - 60px);
      margin: 40px 10px 5px 0;
      float: right;
      position: relative;
      margin-left: 20px;
      border: 1px solid #e8ecf2;
      box-shadow: 1px 4px 6px rgba(80, 80, 80, 0.1);
      .status-icon {
        position: absolute;
        top: -30px;
        left: -31px;
        z-index: 299;
      }
      .time {
        color: #1B1C1E;
        font-size: 12px;
        margin-bottom: 0;
        line-height: 24px;
        position: absolute;
        top: -33px;
        left: 0px;
      }
    }
    &:after {
      width: 2px;
      height: 100%;
      background: #dce5ec;
      content: " ";
      position: absolute;
      top: 0;
      left: 28px;
    }
    .opinion {
      color: #91a1b7;
      max-width: 600px;
      font-size: 12px;
      word-wrap: break-word;
    }
    .flowname {
      color: #636c78;
      max-width: 600px;
      font-size: 12px;
      word-wrap: break-word;
    }
    .head {
      width: 34px;
      height: 34px;
      float: left;
    }
    .cent {
      margin-left: 10px;
      float: left;
      p {
        line-height: 20px;
        font-size: 12px;
        word-break: break-all;
      }
      .name {
        display: flex;
        line-height: 24px;
        .main-name {
          color: #333;
          font-size: 13px;
          line-height: 24px;
          min-height: 24px;
          font-weight: bold;
        }
        .grant-name {
          color: #91a1b7;
          margin-left: 10px;
          .en-icon {
            color: #549ce1;
          }
          .txt {
            margin: 0 3px;
            color: #333;
          }
        }
      }
    }
    .info {
      float: right;
      text-align: right;
      .state {
        text-align: center;
        padding: 0 10px;
        line-height: 22px;
        border-radius: 10px;
        display: inline-block;
        background: #fff;
        color: #606060;
        border: 1px solid #e0e0e0;
      }
    }
    .btn-line {
      .show-process {
        color: #66a7e5;
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }
      .btn-item {
        color: #636c78;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        .en-icon {
          color: #a9b5c6;
          margin-right: 5px;
          display: inline-block;
          vertical-align: middle;
        }
        .txt {
          display: inline-block;
          vertical-align: middle;
        }
        &:hover {
          color: #3e90fe;
          .en-icon {
            color: #3e90fe;
          }
        }
      }
    }
    .flow-notifier {
      font-size: 22px;
      margin-left: 10px;
      color: #cdcdcd;
      &:before {
        content: "\e790";
      }
    }
    .sign-cent {
      img {
        width: 100%;
      }
    }
    .handsign-cent {
      float: left;
      width: 100%;
      text-align: center;
      img {
        width: 288px;
        display: block;
        margin: 0 auto;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #fff;
      }
    }
    &:first-child {
      .state {
        background: #48bff3;
        color: #fff;
        border: 1px solid #48bff3;
      }
    }
  }
  .approval-cent {
    padding: 20px;
    zoom: 1;
    background: #f5f7fa;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .desc {
      /deep/ .el-textarea__inner {
        border: 1px solid #e8ecf2;
        font-size: 12px;
        padding: 10px;
        resize: none;
      }
    }
    .btns {
      margin-top: 10px;
      float: right;
    }
  }
  .sign-cent {
    padding: 20px;
    background: #f5f7fa;
  }
  .showProcess {
    cursor: pointer;
  }
  .notifier-cent {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background: #f5f7fa;
    .person-item {
      width: 62px;
      .en-user-logo {
        margin: 0 auto;
        display: block;
      }
      span {
        display: block;
        text-align: center;
        color: #636c78;
      }
    }
  }
  .process-cent {
    background: #f5f7fa;
  }
  .branch-list {
    border-top: 0;
  }
}
</style>
