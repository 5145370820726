var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.loadingFlow,
          expression: "loadingFlow",
        },
      ],
      staticClass: "seeFlowList",
    },
    [
      _vm.flowData.length > 0
        ? _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.flowData, function (item, index) {
              return _c(
                "dl",
                { key: index },
                [
                  _c("p", { staticClass: "time" }, [
                    _vm._v(_vm._s(_vm.approvalShowTime(item))),
                  ]),
                  _c("en-icon", {
                    staticClass: "status-icon",
                    style: { color: _vm.statusIconColor(item.imgFlag) },
                    attrs: {
                      name: "iconchakanliucheng" + item.imgFlag,
                      size: "16",
                    },
                  }),
                  _c("dd", { staticClass: "main-cent" }, [
                    _c(
                      "div",
                      { staticClass: "head" },
                      [
                        _c("en-user-logo", {
                          attrs: {
                            "user-name":
                              item.pattern === "006"
                                ? item.nodeName
                                : item.name,
                            "image-url": item.userLogo,
                            size: "34",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "cent" }, [
                      _c("div", { staticClass: "name" }, [
                        _c("span", { staticClass: "main-name" }, [
                          _vm._v(
                            _vm._s(
                              item.pattern === "006" ? item.nodeName : item.name
                            )
                          ),
                        ]),
                        _vm.agentPerson(item)
                          ? _c(
                              "div",
                              { staticClass: "grant-name" },
                              [
                                _c("en-icon", {
                                  attrs: {
                                    name: "iconshoufuduixiang-kongjian",
                                    size: "16",
                                  },
                                }),
                                _c("span", { staticClass: "txt" }, [
                                  _vm._v(_vm._s(item.ownerName)),
                                ]),
                                _vm._v("代理执行 "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("p", { staticClass: "opinion" }, [
                        _vm._v(_vm._s(_vm._f("filtersSuggest")(item.suggest))),
                      ]),
                      _c("p", { staticClass: "flowname" }, [
                        _vm._v(
                          _vm._s(
                            item.pattern === "006"
                              ? "分支开始到分支结束"
                              : item.nodeName
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "info" }, [
                      _c(
                        "div",
                        { staticClass: "btn-line" },
                        [
                          _vm.approvalBtn(item)
                            ? _c(
                                "div",
                                {
                                  staticClass: "btn-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeTab(item, "1")
                                    },
                                  },
                                },
                                [
                                  _c("en-icon", {
                                    attrs: { name: "iconshouqian", size: "14" },
                                  }),
                                  _c("span", { staticClass: "txt" }, [
                                    _vm._v("补充意见"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.notifierBtn(item)
                            ? _c(
                                "div",
                                {
                                  staticClass: "btn-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeTab(item, "2")
                                    },
                                  },
                                },
                                [
                                  _c("en-icon", {
                                    attrs: {
                                      name: "icontongzhiren",
                                      size: "14",
                                    },
                                  }),
                                  _c("span", { staticClass: "txt" }, [
                                    _vm._v("通知人"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.signBtn(item)
                            ? _c(
                                "div",
                                {
                                  staticClass: "btn-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeTab(item, "3")
                                    },
                                  },
                                },
                                [
                                  _c("en-icon", {
                                    attrs: { name: "iconshouqian", size: "14" },
                                  }),
                                  _c("span", { staticClass: "txt" }, [
                                    _vm._v("手签"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.pattern === "006"
                            ? _c("en-icon", {
                                staticClass: "show-process",
                                attrs: { name: "iconzhankai", size: "12" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.changeTab(item, "4", index)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  item.showApproval &&
                  _vm.tabCur === item.id &&
                  _vm.flag === "1"
                    ? _c(
                        "dd",
                        { staticClass: "approval-cent" },
                        [
                          _c("el-input", {
                            staticClass: "desc",
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入补充意见",
                            },
                            model: {
                              value: _vm.approvalForm.desc,
                              callback: function ($$v) {
                                _vm.$set(_vm.approvalForm, "desc", $$v)
                              },
                              expression: "approvalForm.desc",
                            },
                          }),
                          _c(
                            "en-button",
                            {
                              staticClass: "btns",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.addSuppleApproval(item, index)
                                },
                              },
                            },
                            [_vm._v("确定")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.showNotifier &&
                  _vm.tabCur === item.id &&
                  _vm.flag === "2"
                    ? _c(
                        "dd",
                        { staticClass: "notifier-cent" },
                        _vm._l(item.notifyRange || "", function (p, pindex) {
                          return _c(
                            "div",
                            { key: pindex, staticClass: "person-item" },
                            [
                              _c("en-user-logo", {
                                attrs: {
                                  "user-name": p.name,
                                  "image-url": p.userLogo,
                                  size: "34",
                                },
                              }),
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(p.name)),
                              ]),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  item.showSign && _vm.tabCur === item.id && _vm.flag === "3"
                    ? _c("dd", { staticClass: "sign-cent" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.getApprovalComment(item, 3),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  item.showProcess && _vm.tabCur === item.id && _vm.flag === "4"
                    ? _c("div", { staticClass: "process-cent" }, [
                        _c(
                          "div",
                          { staticClass: "branch-list" },
                          _vm._l(_vm.brancData, function (bpitem, bpindex) {
                            return _c(
                              "div",
                              { key: bpindex, staticClass: "list branch-mod" },
                              _vm._l(bpitem, function (bitem, bindex) {
                                return _c(
                                  "dl",
                                  { key: bindex },
                                  [
                                    _c("en-icon", {
                                      staticClass: "status-icon",
                                      style: {
                                        color: _vm.statusIconColor(
                                          bitem.imgFlag
                                        ),
                                      },
                                      attrs: {
                                        name:
                                          "iconchakanliucheng" + bitem.imgFlag,
                                        size: "16",
                                      },
                                    }),
                                    _c("dd", { staticClass: "main-cent" }, [
                                      _c(
                                        "div",
                                        { staticClass: "head" },
                                        [
                                          _c("en-user-logo", {
                                            attrs: {
                                              "user-name": bitem.name,
                                              "image-url": bitem.userLogo,
                                              size: "34",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "cent" }, [
                                        _c("div", { staticClass: "name" }, [
                                          _c(
                                            "span",
                                            { staticClass: "main-name" },
                                            [_vm._v(_vm._s(bitem.name))]
                                          ),
                                          _vm.agentPerson(bitem)
                                            ? _c(
                                                "div",
                                                { staticClass: "grant-name" },
                                                [
                                                  _c("en-icon", {
                                                    attrs: {
                                                      name: "iconshoufuduixiang-kongjian",
                                                      size: "16",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "txt" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(bitem.ownerName)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v("代理执行 "),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c("p", { staticClass: "opinion" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("filtersSuggest")(
                                                bitem.suggest
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("p", { staticClass: "flowname" }, [
                                          _vm._v(_vm._s(bitem.nodeName)),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "info" }, [
                                        _c("p", { staticClass: "time" }, [
                                          _vm._v(_vm._s(bitem.modifyTime)),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _c("en-result", { attrs: { type: "NoData" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }